export const filterList = {
  payments: [
    {
      value: 0,
      text: "no payments",
    },
    {
      value: 1,
      text: "registered ",
    },
    {
      value: 2,

      text: "payment delivered",
    },
  ],
  carfax: [
    { value: 0, text: "no carfax" },
    { value: 1, text: "active carfax" },
    { value: 2, text: "expired carfax" },
  ],
  recall: [
    { value: 0, text: "no registry" },
    { value: 2, text: "active " },
    { value: 1, text: "completed " },
    { value: 3, text: "WIP " },
    { value: 4, text: "no solution" },
  ],
  visualInspection: [
    { value: 0, text: "no v.i." },
    { value: 1, text: "w.i.p." },
    { value: 2, text: "done" },
  ],
  workOrders: [
    { value: 0, text: "no w.o." },
    { value: 1, text: "w.o. to quote" },
    { value: 2, text: "active w.o." },
    { value: 3, text: "w.o. to validate" },
    { value: 4, text: "w.o. done" },
  ],
  carwash: [
    { value: 0, text: "no carwash" },
    { value: 1, text: "active carwash" },
    { value: 2, text: "carwash done" },
  ],
  transfer: [
    { value: 0, text: "no transfer" },
    { value: 1, text: "active transfer" },
    { value: 2, text: "completed transfers" },

  ],
  files: [
    { value: 0, text: "no files" },
    { value: 1, text: "complete" },
    { value: 2, text: "no QC. ownership" },
    { value: 3, text: "no 7501 form" },
    { value: 4, text: "no US title before reassignment" },
    { value: 5, text: "no US title after reassignment" },
    { value: 6, text: "no Deposit check" },
    { value: 7, text: "no export load" },
    { value: 8, text: "export load active" },
    { value: 9, text: "export load shipped" },
    { value: 10, text: "export load landed" },


  ],
  stockNumber: [
    { value: 0, text: "no stock" },
    { value: 1, text: "missing info" },
    { value: 2, text: "Delayed (10d)" },
    { value: 3, text: "complete" },
    { value: 4, text: "no contract" },
    { value: 5, text: "no registration" },
    { value: 6, text: "consignment" }
  ],
  lien: [
    { value: 0, text: "no lien" },
    { value: 1, text: "completed lien" },
    { value: 2, text: "active lien" },
    { value: 3, text: "no quittance" }
  ],
  creditRequest: [
    { value: 0, text: "no c.r." },
    { value: 1, text: "credit request" },
    { value: 2, text: "delayed c.r." },
    { value: 3, text: "reimbursed" },
  ],

  registration: [
    { value: 0, text: "no registration" },
    { value: 2, text: "odo" },
    { value: 3, text: "saaq" },
    { value: 6, text: "copy reg" },
    { value: 4, text: "problems" },
    { value: 5, text: "✔" },
  ],
  conditionalReports: [
    { value: 0, text: "no cr" },
    { value: 1, text: "cr" },
  ],
  cluster: [
    { value: 0, text: "no cluster" },
    { value: 1, text: "active cluster" },
    { value: 2, text: "completed cluster" },
  ],
  offers: [
    { value: 2, text: "last offer of the admin" },
    { value: 1, text: "last customer offer" },
    { value: 0, text: "no offers" },
  ],
  sold: [
    { value: 1, text: "not payed" },
    { value: 2, text: "paid out" },
  ],
  reserve: [
    { value: 1, text: "reserved" },
    { value: 0, text: "not reserved" },
  ],
  addons: [
    { value: 0, text: "no addons" },
    { value: 1, text: "without category addons" },
    { value: 2, text: "completed addons" }
  ],
  soldInfo: [
    { value: 1, text: "not sold" },
  ]
};
