<template>
  <div class="container-fluid">
    <br />
    <div v-if="isVisualInspectionCR" class="accordion" role="tablist">
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button
            block
            v-b-toggle.accordion-header="'accordion-trims'"
            class="text-left"
            variant="dark-green"
          >
            Trims
          </b-button>
        </b-card-header>
        <b-collapse
          id="accordion-trims"
          accordion="my-accordion"
          role="tabpanel"
        >
          <b-card class="shadow mb-3 p-0" body-class="p-0">
            <CarsTable
              v-if="registryCar.vinNumber"
              :refresh-data="getData"
              :vinNumber="registryCar.vinNumber ? registryCar.vinNumber : ''"
            />
          </b-card>
        </b-collapse>
      </b-card>
    </div>
    <VinData
      v-if="registryCar.id"
      :car-prop="registryCar"
      :reserved-price-prop="reservedPrice"
      :disabled="false"
      @isInvalid="(val) => (vinDataInvalid = val)"
      @update="(c) => (registryCar = c)"
    />
    <b-row>
      <b-col>
        <b-card class="shadow mb-3">
          <div>
            <b-row>
              <b-col
                sm="12"
                md="12"
                lg="4"
                xl="4"
                class="d-flex justify-content-end"
              >
                <b-button
                  variant="outline-primary"
                  class=""
                  @click="getCRFile()"
                  :disabled="isVicrFileLoading"
                >
                  <!-- loader -->

                  <span>
                    Download CR
                    <b-spinner
                      v-if="isVicrFileLoading"
                      small
                      label="Loading..."
                    ></b-spinner
                  ></span>
                </b-button>
                <div class="mb-2">
                  <span class="h4">Mechanical notes</span>
                  <img
                    class="ml-3 mb-2"
                    width="40px"
                    src="@/assets/img/engine-icon.png"
                    alt="engine"
                  />
                  <b-button
                    v-if="userHasPermission('vi', 'write')"
                    class="ml-3"
                    variant="light"
                    size="sm"
                    @click="
                      isEditingMechanicalNotes = !isEditingMechanicalNotes
                    "
                  >
                    <b-icon :icon="isEditingMechanicalNotes ? 'x' : 'pencil'" />
                  </b-button>
                </div>
              </b-col>
              <b-col sm="12" md="12" lg="8" xl="8">
                <b-form-textarea
                  v-if="isEditingMechanicalNotes"
                  id="textarea"
                  v-model="mechanicalNote"
                  class="mb-3"
                  autofocus
                  trim
                  placeholder="Enter something..."
                  rows="3"
                  max-rows="6"
                />
                <p v-else>
                  <i>
                    <template v-if="!mechanicalNote">
                      No mechanical notes
                    </template>
                    <div
                      v-for="(line, i) in mechanicalNote.split('\n')"
                      :key="i"
                      v-else
                    >
                      {{ line }}
                      <br />
                    </div>
                  </i>
                </p>
                <div class="d-flex justify-content-end">
                  <b-button
                    v-if="isEditingMechanicalNotes"
                    size="sm"
                    variant="primary"
                    @click="saveMechanicalNotes"
                  >
                    Save
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-card v-if="isVisualInspectionCR">
      <h2 class="d-flex">
        No damages
        <b-form-checkbox
          @change="onChangeDamageStatus"
          v-model="vehicleHasDamage"
          name="checkbox-1"
          class="ml-3"
          size="lg"
          :value="1"
          :unchecked-value="0"
        >
        </b-form-checkbox>
      </h2>
      <!-- checkbox -->
    </b-card>
    <QuickConditionRegistration
      v-if="registryCar.condition"
      :vehicleCondition="registryCar.condition"
      @onConditionSelect="onConditionSelected($event)"
    />
    <b-row>
      <b-col class="mt-3" sm="12" md="6" lg="4" xl="4">
        <b-card class="shadow">
          <div class="mb-2 mt-1">
            <span class="h4">Pics</span>
            <b-button
              v-if="userHasPermission('vi', 'write')"
              class="ml-3"
              variant="primary"
              @click="$bvModal.show('modal-add-pic')"
            >
              <b-icon icon="plus-circle" />
            </b-button>

            <b-button
              v-if="userHasPermission('vi', 'write')"
              @click="goToAutomaticInspection"
              variant="success"
              class="mx-2"
            >
              <font-awesome-icon
                class="icon-color cursor-pointer"
                style="color: #fff"
                :icon="['far', 'camera-viewfinder']"
            /></b-button>
            <b-button
              v-if="userHasPermission('vi', 'write')"
              @click="goToEasyWizard"
              variant="warning"
              class="mx-2"
            >
              <font-awesome-icon
                class="icon-color cursor-pointer"
                style="color: #fff"
                :icon="['far', 'camera-viewfinder']"
              />
            </b-button>

            <!-- edit images -->
            <b-button
              v-if="userHasPermission('vi', 'write') && isVisualInspectionCR"
              variant="primary"
              class="mx-2"
              @click="openEditPartsImage"
            >
              <font-awesome-icon
                class="icon-color cursor-pointer"
                style="color: #fff"
                :icon="['far', 'pen-to-square']"
              />
            </b-button>
          </div>
          <div style="width: 100%; height: 33rem; overflow-y: scroll">
            <b-list-group>
              <b-list-group-item v-if="listPics.length === 0">
                Hey! Looks like you are the first person to take some pics
              </b-list-group-item>
              <b-list-group-item v-for="(p, i) in listPics" :key="i">
                <div class="d-flex justify-content-between">
                  <div class="d-flex flex-row">
                    <div>
                      <Photoswipe rotate>
                        <img
                          style="width: 8rem"
                          class="img-thumbnail mr-3"
                          :src="p.photoThumbnail"
                          v-pswp="p.photo"
                          alt="car"
                        />
                      </Photoswipe>
                      <div class="text-center mt-2">
                        <b-icon
                          v-if="userHasPermission('vi', 'write')"
                          icon="arrow-counterclockwise"
                          class="mr-3 cursor-pointer"
                          scale="1.5"
                          @click="rotateImage(p.id, 'part', 90)"
                        ></b-icon>
                        <b-icon
                          v-if="userHasPermission('vi', 'write')"
                          icon="arrow-clockwise"
                          class="mx-3 cursor-pointer"
                          scale="1.5"
                          @click="rotateImage(p.id, 'part', 270)"
                        ></b-icon>
                      </div>
                    </div>
                    <div>
                      <p>{{ p.part.name }}</p>
                      <p v-if="isVisualInspectionCR">
                        {{ p.createdAt | formatDate }}
                      </p>
                      <p v-if="isVisualInspectionCR">
                        <span v-if="p.user">
                          {{ p.user.firstName }} {{ p.user.lastName }}
                        </span>
                      </p>
                      <br />
                      <b-button
                        v-if="userHasPermission('vi', 'write')"
                        :disabled="isLoadingDelete"
                        variant="danger"
                        size="sm"
                        @click="deletePic(p)"
                        v-b-tooltip.hover
                        title="Delete image"
                      >
                        <b-icon variant="secondary" scale="1" icon="trash" />
                      </b-button>
                    </div>
                  </div>
                </div>
              </b-list-group-item>
            </b-list-group>
          </div>
        </b-card>
      </b-col>
      <b-col
        sm="12"
        md="6"
        lg="4"
        xl="4"
        class="d-flex justify-content-center mt-3"
      >
        <b-tabs v-if="!loadingDost" content-class="mt-3" justified pills>
          <b-tab
            title="Exterior"
            :active="currentTab === 'exterior'"
            @click="changeTab('exterior')"
          >
            <AutoParts
              :dots-exterior="dotsExterior"
              :url="url"
              :vehicle="registryCar"
              @setDot="setDot"
              :isVisualInspectionCR="isVisualInspectionCR"
            />
          </b-tab>
          <b-tab
            title="Interior"
            :active="currentTab === 'interior'"
            @click="changeTab('interior')"
          >
            <AutoPartsInterior
              :dots-exterior="dotsInterior"
              :url="url"
              :vehicle="registryCar"
              @setDot="setDot"
              :isVisualInspectionCR="isVisualInspectionCR"
            />
          </b-tab>
        </b-tabs>
        <div v-else class="text-center text-purple-blue my-2">
          <b-spinner variant="purple-blue" class="align-middle" />
          <p>Loading...</p>
        </div>
      </b-col>

      <b-col class="mt-3" sm="12" md="12" lg="4" xl="4">
        <b-card class="shadow">
          <div class="mb-3">
            <span class="h4">Damage Pics</span>
          </div>
          <div style="width: 100%; height: 25rem; overflow-y: scroll">
            <b-list-group>
              <b-list-group-item v-if="damageList.length === 0">
                Hey! Looks like you are the first person to take a damage pics
              </b-list-group-item>
              <b-list-group-item v-for="(d, i) in damageList" :key="i">
                <b-media>
                  <template #aside>
                    <div class="d-flex align-items-start flex-column">
                      <div class="mb-auto">
                        <Photoswipe>
                          <img
                            style="width: 8rem"
                            class="img-thumbnail mr-3"
                            :src="d.photoThumbnail"
                            v-pswp="d.damagePhoto"
                            alt="car"
                          />
                        </Photoswipe>
                      </div>
                      <div class="text-center mt-2">
                        <b-icon
                          v-if="userHasPermission('vi', 'write')"
                          icon="arrow-counterclockwise"
                          class="mx-4 cursor-pointer"
                          scale="1.5"
                          @click="rotateImage(d.id, 'damage', 90)"
                        ></b-icon>
                        <b-icon
                          v-if="userHasPermission('vi', 'write')"
                          icon="arrow-clockwise"
                          class="mx-3 cursor-pointer"
                          scale="1.5"
                          @click="rotateImage(d.id, 'damage', 270)"
                        ></b-icon>
                      </div>
                    </div>

                    <!--   <img
                      width="100"
                      :src="d.damagePhoto"
                      :alt="d.description"
                      class="img-thumbnail mr-3"
                    /> -->
                  </template>

                  <p>
                    <span v-if="d.part">
                      <strong>Part:</strong> {{ d.part.name }}
                    </span>
                    <br />
                    <span v-if="d.damage">
                      <strong>Damage:</strong> {{ d.damage.name }}
                    </span>
                  </p>
                  <p class="text-break">
                    {{ d.description }}
                  </p>
                  <b-media-aside class="float-right" right>
                    <b-button
                      v-if="userHasPermission('vi', 'write')"
                      :disabled="isLoadingDelete"
                      variant="success"
                      size="sm"
                      @click="openRepairModal(d)"
                      v-b-tooltip.hover
                      title="Upload repair photo"
                    >
                      <font-awesome-icon
                        class="icon-color cursor-pointer"
                        style="color: #fff"
                        :icon="['far', 'camera-viewfinder']"
                      />
                    </b-button>
                    <b-button
                      v-if="userHasPermission('vi', 'write')"
                      :disabled="isLoadingDelete"
                      variant="danger"
                      size="sm"
                      @click="deleteDamagePic(d)"
                      v-b-tooltip.hover
                      title="Delete image"
                    >
                      <b-icon variant="secondary" scale="1" icon="trash" />
                    </b-button>
                  </b-media-aside>
                </b-media>
              </b-list-group-item>
            </b-list-group>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mt-3 d-flex justify-content-between">
        <b-button
          v-if="!isVisualInspectionCR"
          size="lg"
          variant="danger"
          :disabled="vinDataInvalid"
          @click="back()"
        >
          <b-icon icon="chevron-compact-left" scale="1" />
          Prev
        </b-button>

        <b-button
          size="lg"
          variant="primary"
          :disabled="vinDataInvalid"
          @click="goToTable()"
        >
          Finish
          <b-icon icon="chevron-compact-right" scale="1" />
        </b-button>
      </b-col>
    </b-row>

    <b-modal
      id="modal-add-pic"
      centered
      hide-footer
      no-close-on-backdrop
      title="New pic"
      @hidden="imageToUpload = ''"
    >
      <form
        @submit.prevent="
          isVisualInspectionCR ? openPreviewImagesModal() : addPhotosNoPreview()
        "
      >
        <div v-if="imageToUpload">
          <label>Preview</label>
          <img
            class="img-thumbnail main-img"
            :src="imageToUpload"
            alt="preview"
          />
          <small>This will be the aspect of the images in the auction.</small>
        </div>
        <b-form-group label="Image">
          <b-form-file
            v-model="formAddPic.photo"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            accept="image/*"
            @input="onInputImageToUpload"
            multiple
          />
        </b-form-group>
        <b-form-group label="Part">
          <b-form-select v-model="formAddPic.partsId" class="mb-3">
            <b-form-select-option
              v-for="(p, i) of autoParts"
              :key="i"
              :value="p.id"
            >
              {{ p.name }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
        <div class="d-flex justify-content-end">
          <b-button
            :disabled="!formAddPic.photo || isLoadingFormAddPic"
            type="submit"
            variant="success"
          >
            <b-spinner v-if="isLoadingFormAddPic" label="Spinning" />
            <b-icon v-else icon="plus" />
          </b-button>
        </div>
      </form>
    </b-modal>
    <UploadRepairPhotoModalVue
      :damageId="damageSelected ? damageSelected.id : null"
      :repairPhoto="damageSelected ? damageSelected.repairPhoto : ''"
      :status="damageSelected ? damageSelected.status : null"
      @onChangeStatus="onChangeStatusDamageSelected"
    />

    <b-modal
      v-model="showUploadProgress"
      title="Uploading Images"
      hide-footer
      no-close-on-backdrop
    >
      <p class="my-4">
        Uploading {{ uploadedImages }} of {{ totalImages }} images
      </p>
      <b-progress :value="uploadProgress" max="100" class="mb-2"></b-progress>
    </b-modal>

    <PreviewImages
      v-if="showPreviewImagesModal"
      ref="previewImages"
      :vehicleImages="imagePreview"
      @onConfirmImages="onConfirmImages"
      :isUpdatePreviewImages="isUpdatePreviewImages"
      :showModal="showPreviewImagesModal"
      @onCloseModal="showPreviewImagesModal = false"
    />
  </div>
</template>

<script>
import { companyCarsMixin } from "@/modules/vehicle/components/visual-inspection/mixin";
import { generalMixin } from "@/modules/mixin.js";
import VinData from "@/modules/vehicle/components/visual-inspection/components/RegistryCar/VinData.vue";

import AutoParts from "@/modules/vehicle/components/visual-inspection/components/RegistryCar/AutoParts.vue";
import AutoPartsInterior from "@/modules/vehicle/components/visual-inspection/components/RegistryCar/AutoPartsInterior.vue";
import CompanyCarsService from "@/services/companyCarsService";
import UploadRepairPhotoModalVue from "./UploadRepairPhotoModal.vue";
import { mapState } from "vuex";
import { superAdminMixin } from "../../../../../superAdmin/mixins";
import QuickConditionRegistration from "./QuickConditionRegistration.vue";
import PreviewImages from "../PreviewImages.vue";
import CarsTable from "@/modules/vehicle/components/CarsTable";
import { registryVehicleMixin } from "../../../../../admin/vehicles/registry/mixins";
export default {
  components: {
    VinData,
    AutoParts,
    AutoPartsInterior,
    UploadRepairPhotoModalVue,
    QuickConditionRegistration,
    PreviewImages,
    CarsTable,
  },
  mixins: [
    companyCarsMixin,
    generalMixin,
    superAdminMixin,
    registryVehicleMixin,
  ],
  data() {
    return {
      imageToUpload: "",
      isEditingMechanicalNotes: false,
      mechanicalNote: "",
      dotsExterior: [],
      dotsInterior: [],
      loadingDost: true,
      listDamage: [],
      listPics: [],
      previews: {
        mainBody: null,
        front: null,
        back: null,
        driverSide: null,
        coDriver: null,
        passengerLeftSide: null,
        passengerRightSide: null,
      },
      photos: {
        mainBody: null,
        front: null,
        back: null,
        driverSide: null,
        coDriver: null,
        passengerLeftSide: null,
        passengerRightSide: null,
        checkDamages: null,
        generalRate: 0,
      },
      showDamagePicForm: false,
      url: `#/vin/create/${this.$route.params.vin}/exterior`,
      currentCarPart: "",
      formAddPic: {
        partsId: null,
        photo: null,
      },
      isLoadingFormAddPic: false,
      isLoadingDelete: false,
      autoParts: [],
      currentTab: "",
      damageSelected: null,
      showUploadProgress: false,
      totalImages: 0,
      uploadedImages: 0,
      partsIds: [
        10, 17, 8, 7, 1, 15, 2, 11, 90, 91, 89, 4, 3, 93, 92, 12, 5, 6,
      ], // Provided partsId array
      uploadProgress: 0,
      vehicleHasDamage: 0,
      showPreviewImagesModal: false,
      imagePreview: [],
      imagesConfirmed: [],
      isUpdatePreviewImages: false,
      isVicrFileLoading: false,
    };
  },
  computed: {
    ...mapState({
      vinData: (state) => state.vehicle.visualInspection.vinData,
    }),
    damageList() {
      return this.listDamage;
    },

    isVisualInspectionCR() {
      return this.$route.name === "company.cars.exterior.cr";
    },
  },
  mounted() {
    this.$store.commit("visualInspection/resetStore");
    this.getAutoParts();
    /*     this.getVehicleByVin();
     */
    this.getData();
  },
  methods: {
    onInputImageToUpload(file) {
      const reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
      }
      reader.onloadend = () => {
        this.imageToUpload = reader.result;
      };
    },
    changeTab(tab) {
      this.currentTab = tab;
      this.$store.commit("visualInspection/setDamageImageModal", null);
      this.$store.commit("visualInspection/setRepairImageModal", null);
    },
    openPreviewImagesModal() {
      if (this.formAddPic.photo.length === 0) {
        this.makeToast(
          "Warning",
          "You should select at least one photo to continue",
          "warning"
        );
        return;
      }
      this.isUpdatePreviewImages = false;
      this.formatImagesToPreview();
      this.showPreviewImagesModal = true;
    },
    onConfirmImages(imagesWithPartId) {
      this.imagesConfirmed = imagesWithPartId;
      if (this.imagesConfirmed.length === 0) {
        this.makeToast(
          "Warning",
          "You should select at least one photo to continue",
          "warning"
        );
        return;
      }
      this.addPhoto();
    },
    formatImagesToPreview() {
      this.imagePreview = [];

      Array.from(this.formAddPic.photo).forEach((file) => {
        const imageUrl = URL.createObjectURL(file);
        this.imagePreview.push({
          file: file,
          url: imageUrl,
        });
      });
    },
    async addPhoto() {
      try {
        this.updateCar();
        this.isLoadingFormAddPic = true;

        /*     if (this.formAddPic.photo.length === 0) {
          this.makeToast(
            "Warning",
            "You should select at least one photo to continue",
            "warning"
          );
          return;
        } */

        this.totalImages = this.imagesConfirmed.length;
        this.uploadedImages = 0;
        this.uploadProgress = 0;
        this.showUploadProgress = true;
        if (
          (Array.isArray(this.formAddPic.photo) ||
            this.isUpdatePreviewImages) &&
          this.imagesConfirmed.length > 0
        ) {
          for (let i = 0; i < this.imagesConfirmed.length; i++) {
            const photo = this.imagesConfirmed[i]?.image?.file;
            const partsId = this.imagesConfirmed[i].id;
            const fileID = this.imagesConfirmed[i]?.image?.fileId;
            await CompanyCarsService.postPic({
              photo: photo,
              partsId,
              vehiclesId: this.carId,
              vi: this.isVisualInspectionCR ? 1 : 0,
              id: fileID,
            });

            this.uploadedImages++;
            this.uploadProgress =
              (this.uploadedImages / this.totalImages) * 100;
          }
        } else {
          await CompanyCarsService.postPic({
            photo: this.formAddPic.photo,
            partsId: this.formAddPic.partsId,
            vehiclesId: this.carId,
            vi: this.isVisualInspectionCR ? 1 : 0,
          });
        }

        this.makeToast("Success", "Photos added successfully", "success");

        // Reset form
        this.formAddPic = {
          partsId: null,
          photo: [],
        };
        this.getData();
      } catch (error) {
        console.log("error", error);
        this.makeToast(
          "Error",
          "Internal server error, try again later",
          "danger"
        );
      } finally {
        this.isLoadingFormAddPic = false;
        this.imageToUpload = "";
        this.showUploadProgress = false;
        this.showPreviewImagesModal = false;
        this.isUpdatePreviewImages = false;
      }
    },
    async addPhotosNoPreview() {
      try {
        this.updateCar();
        this.isLoadingFormAddPic = true;

        if (this.formAddPic.photo.length === 0) {
          this.makeToast(
            "Warning",
            "You should select at least one photo to continue",
            "warning"
          );
          return;
        }

        this.totalImages = this.formAddPic.photo.length;
        this.uploadedImages = 0;
        this.uploadProgress = 0;
        this.showUploadProgress = true;
        if (
          Array.isArray(this.formAddPic.photo) &&
          this.formAddPic.photo.length > 1
        ) {
          for (let i = 0; i < this.formAddPic.photo.length; i++) {
            const photo = this.formAddPic.photo[i];
            const partsId = this.partsIds[i]; // Get the partsId from the provided array

            await CompanyCarsService.postPic({
              photo: photo,
              partsId,
              vehiclesId: this.carId,
              vi: this.isVisualInspectionCR ? 1 : 0,
            });

            this.uploadedImages++;
            this.uploadProgress =
              (this.uploadedImages / this.totalImages) * 100;
          }
        } else {
          await CompanyCarsService.postPic({
            photo: this.formAddPic.photo,
            partsId: this.formAddPic.partsId,
            vehiclesId: this.carId,
            vi: this.isVisualInspectionCR ? 1 : 0,
          });
        }

        this.makeToast("Success", "Photos added successfully", "success");

        // Reset form
        this.formAddPic = {
          partsId: null,
          photo: [],
        };
        this.getData();
      } catch (error) {
        console.log("error", error);
        this.makeToast(
          "Error",
          "Internal server error, try again later",
          "danger"
        );
      } finally {
        this.isLoadingFormAddPic = false;
        this.imageToUpload = "";
        this.showUploadProgress = false;
      }
    },

    async setDot(dot) {
      this.loadingDost = true;
      try {
        await CompanyCarsService.postDamagePic({
          description: dot.description,
          damagePhoto: dot.image,
          vehiclesId: this.carId,
          color: dot.color,
          coordinateX: dot.x,
          coordinateY: dot.y,
          type: dot.side,
          damageId: dot.damageId,
          partId: dot.partId,
          vi: this.isVisualInspectionCR ? 1 : 0,
        });
        this.makeToast("Success", "Photo added successfully", "success");
        this.getData();
        setTimeout(() => {
          this.loadingDost = false;
        }, 500);
      } catch (error) {
        this.makeToast(
          "Error",
          "Internal server error, try again later",
          "danger"
        );
      }
      this.listDamage = [...this.listDamage, dot];
    },
    async deletePic(pic) {
      const value = await this.showConfirmDeleteBox();
      if (!value) return;
      try {
        this.isLoadingDelete = true;
        await CompanyCarsService.deletePic({
          id: pic.id,
          vi: this.isVisualInspectionCR ? 1 : 0,
        });
        this.makeToast("Success", "Photo deleted successfully", "success");
        this.getData();
      } catch (error) {
        this.makeToast(
          "Error",
          "Internal server error, try again later",
          "danger"
        );
      } finally {
        this.isLoadingDelete = false;
      }
    },
    async deleteDamagePic(damage) {
      const value = await this.showConfirmDeleteBox();
      if (!value) return;
      try {
        this.isLoadingDelete = true;
        await CompanyCarsService.deleteDamagePic({
          id: damage.id,
          vi: this.isVisualInspectionCR ? 1 : 0,
        });
        this.makeToast("Success", "Photo deleted successfully", "success");
        this.getData();
        this.loadingDost = true;
        setTimeout(() => {
          this.loadingDost = false;
        }, 500);
      } catch (error) {
        console.log("error loading dots", error);

        this.makeToast(
          "Error",
          "Internal server error, try again later",
          "danger"
        );
      } finally {
        this.isLoadingDelete = false;
      }
    },
    async getAutoParts() {
      const data = await CompanyCarsService.getAutoParts();
      this.autoParts = data.externalPart;
    },
    saveMechanicalNotes() {
      this.$store.commit("visualInspection/setVehicleInformation", {
        mechanicalNote: this.mechanicalNote,
        type: "mechanical",
      });
      this.updateCar(() => {
        this.makeToast("Success", "Mechanical notes saved", "success");
        this.isEditingMechanicalNotes = false;
      });
    },
    back() {
      this.$store.commit("visualInspection/setVinData", {
        ...this.registryCar,
      });
      this.$store.commit("visualInspection/setVehicleInformation", {
        ...this.vehicleInformation,
        mechanicalNote: this.mechanicalNote,
        color: this.registryCar.color,
        kms: this.registryCar.kms,
      });
      this.$store.commit("visualInspection/setVehicleCostDatail", {
        cost: this.registryCar.reservePrice,
      });
      this.$store.commit("visualInspection/setVehicleExterior", {
        ...this.photos,
      });
      if (this.userHasPermission("vi", "write")) {
        this.updateCar(
          () => {
            this.$router.push({
              name: "company.cars.general",
              params: { vin: this.$route.params.vin },
            });
          },
          (error) => {
            this.makeToast("Error", error.response.data.message, "danger");
          }
        );
      } else {
        this.$router.push({
          name: "company.cars.general",
          params: { vin: this.$route.params.vin },
        });
      }
    },
    nextPage() {
      this.$store.commit("visualInspection/setVehicleInformation", {
        mechanicalNote: this.mechanicalNote,
        color: this.registryCar.color,
        kms: this.registryCar.kms,
      });
      this.$store.commit("visualInspection/setVinData", {
        ...this.registryCar,
      });
      this.$store.commit("visualInspection/setVehicleCostDatail", {
        cost: this.registryCar.reservePrice,
      });
      this.$store.commit("visualInspection/setVehicleExterior", {
        ...this.photos,
      });
      this.updateCar(
        () => {
          this.$router.push({
            name: "company.cars.uploaded",
            params: { vin: this.$route.params.vin },
          });
        },
        (error) => {
          this.makeToast("Error", error.response.data.message, "danger");
        }
      );
    },
    getData() {
      this.getVin(this.$route.params.vin, (data) => {
        this.loadingDost = true;
        this.carId = data.id;
        console.log("data", data);
        if (data.vehicleFlags) {
          this.registryCar.vehicleFlags = data.vehicleFlags;
          this.vehicleHasDamage = data.vehicleFlags.damage ?? 0;
        }
        if (data.vinNumber) {
          this.registryCar = data.vinNumber;
          this.registryCar.condition = data.vinNumber.typePrice;
        }
        if (data.vehicleInformation) {
          this.registryCar.color = data.vehicleInformation.color;
          this.vehicleInformation = data.vehicleInformation;
          this.registryCar.kms = data.vehicleInformation.kms;
          this.registryCar.miles = data.vehicleInformation.miles;
          this.registryCar.stockNumber = data.stockNumber;
          this.registryCar.agent = data.user
            ? data?.user?.firstName + " " + data?.user?.lastName
            : "";
          data?.user?.firstName + " " + data?.user?.lastName;
          this.vehicleInformation = data.vehicleInformation;
        }
        if (data.vehicleCostDetail) {
          this.registryCar.cost = data.vehicleCostDetail.cost
            ? data.vehicleCostDetail.cost
            : 0;
          this.reservedPrice = data.vehicleCostDetail.reservedPrice
            ? data.vehicleCostDetail.reservePrice
            : 0;
        }
        if (data.vehicleExteriors) {
          this.previews = data.vehicleExteriors;
          this.photos.generalRate = data.vehicleExteriors.generalRate;
        }
        if (data.vehicleHasParts) {
          this.listPics = data.vehicleHasParts;
        }
        if (this.isVisualInspectionCR) {
          console.log("isVisualInspection");
          this.listPics = data.visualInspection;
          console.log("listPics", this.listPics);
        }

        if (data.vehicleInformation.mechanicalNote) {
          this.mechanicalNote = data.vehicleInformation.mechanicalNote;
        }

        if (data.damagePhotos) {
          this.listDamage = data.damagePhotos;
          this.dotsExterior = [];
          this.dotsInterior = [];
          for (const damage of data.damagePhotos) {
            if (damage.type === "interior") {
              this.dotsInterior = [
                ...this.dotsInterior,
                {
                  x: damage.coordinateX,
                  y: damage.coordinateY,
                  color: damage.color,
                  damagePhoto: damage.damagePhoto,
                  status: damage.status,
                  repairPhoto: damage.repairPhoto ?? "",
                },
              ];
            } else if (damage.type === "exterior") {
              this.dotsExterior = [
                ...this.dotsExterior,
                {
                  x: damage.coordinateX,
                  y: damage.coordinateY,
                  color: damage.color,
                  damagePhoto: damage.damagePhoto,
                  status: damage.status,
                  repairPhoto: damage.repairPhoto ?? "",
                },
              ];
            }
          }
          this.loadingDost = false;
        }
        this.loadingDost = false;
      });
    },
    goToTable() {
      this.$store.commit("visualInspection/setVinData", {
        ...this.registryCar,
      });
      this.$store.commit("visualInspection/setVehicleInformation", {
        ...this.vehicleInformation,
        color: this.registryCar.color,
        kms: this.registryCar.kms,
        dateMFG: this.registryCar.dateMFG,
        gvw: this.registryCar.gvw,
      });

      this.updateCar(
        () => {
          this.$router.push({ name: "superadmin.vehicles.table.inventory" });
        },
        (error) => {
          this.makeToast("Error", error.response.data.message, "danger");
        }
      );
    },
    goToAutomaticInspection() {
      if (this.isVisualInspectionCR) {
        this.$router.push({
          name: "company.cars.automaticInspection.cr",
          params: { vin: this.$route.params.vin },
        });
      } else {
        this.$router.push({
          name: "company.cars.automaticInspection",
          params: { vin: this.$route.params.vin },
        });
      }
    },
    goToEasyWizard() {
      if (this.isVisualInspectionCR) {
        this.$router.push({
          name: "company.cars.automaticInspection.easy.cr",
          params: { vin: this.$route.params.vin },
        });
      } else {
        this.$router.push({
          name: "company.cars.automaticInspection.easy",
          params: { vin: this.$route.params.vin },
        });
      }
    },
    rotateImage(id, type, degrees) {
      this.$store
        .dispatch("PUT_ROTATE_IMAGE", {
          id,
          type,
          rotate: degrees,
          vi: this.isVisualInspectionCR ? 1 : 0,
        })
        .then(() => {
          this.getData();

          this.makeToast("Success", "Image rotated", "success");
        })
        .catch((error) => {
          this.makeToast("Error", error.response.data.message, "danger");
        });
    },
    openRepairModal(damage) {
      this.damageSelected = { ...damage };
      this.$bvModal.show("modal-repair-pic");
    },
    onChangeStatusDamageSelected(status) {
      this.damageSelected.status = status;
    },
    onConditionSelected(condition) {
      this.$store.commit("visualInspection/setVinData", {
        ...this.registryCar,
        typePrice: condition,
      });

      this.registryCar.typePrice = condition;
    },

    refreshData() {
      this.getData();
      this.loadingDost = true;
      setTimeout(() => {
        this.loadingDost = false;
      }, 500);
    },
    onChangeDamageStatus(status) {
      this.$store
        .dispatch("PUT_REGISTER_DAMAGE", {
          id: this.carId,
          status: status,
        })
        .then(() => {
          this.makeToast("Success", "Status updated", "success");
        });
    },
    openEditPartsImage() {
      this.isUpdatePreviewImages = true;
      this.imagePreview = this.listPics.map((p) => {
        return {
          photo: p.photo,
          part: p.partsId,
          url: p.photoThumbnail,
          fileId: p.id,
        };
      });
      console.log("imagePreview", this.imagePreview);
      this.showPreviewImagesModal = true;
      /* wait 1 second */
      setTimeout(() => {
        /*         this.$refs.previewImagesModal.$slots.default[0].context.$refs.previewImages.formatImagesPreCharged();
         */
      }, 300);
    },
    getCRFile() {
      console.log(this.registryCar);
      this.isVicrFileLoading = true;

      this.$store
        .dispatch("GET_CR_FILE", { id: this.carId })
        .then((response) => {
          if (!response.error) {
            this.isVicrFileLoading = false;
            const url = response.url;
            const link = document.createElement("a");
            /* open in blank */
            link.target = "_blank";
            link.setAttribute("href", url);
            link.setAttribute(
              "download",
              this.registryCar.stockNumber + ".pdf"
            );
            document.body.appendChild(link);
            link.click();
          }
        })
        .catch((error) => {
          this.isVicrFileLoading = false;
          this.makeToast("Error", error.response.data.message, "danger");
        });
    },
  },
};
</script>

<style scoped>
.img-container {
  border-radius: 10px;
  box-shadow: 10px 5px 5px gray;
}

.hide {
  display: none;
}

.imgUpload {
  min-width: 100px;
  min-height: auto;
}

.preview {
  min-width: 150;
  min-width: auto;
}

.scrollmenu {
  overflow: auto;
  white-space: nowrap;
}

.main-img {
  width: 100%;
  height: 25rem;
  object-fit: cover;
}
</style>
