<template>
  <div>
    <template v-if="isSelectMode">
      <br />
      Select vehicles to re-import
    </template>
    <b-table
      :items="vehicles"
      :fields="fields"
      responsive="sm"
      ref="selectableTable"
      :selectable="isSelectMode"
      @row-selected="onRowSelected"
    >
      <template #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <span aria-hidden="true">&check;</span>
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <span aria-hidden="true">&nbsp;</span>
          <span class="sr-only">Not selected</span>
        </template>
      </template>
    </b-table>
  </div>
</template>
<script>
export default {
  name: "VehiclesTable",
  props: {
    vehicles: {
      type: Array,
      required: true,
    },
    isSelectMode: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      fields: [
        {
          key: "selected",
        },
        {
          key: "stockNumber",
          label: "Stock Number",
          class: "text-center",
        },
        {
          key: "vinNumber",
          label: "VIN",
          class: "text-center",
        },
        {
          key: "year",
          label: "Year",
          class: "text-center",
        },
        {
          key: "make",
          label: "Make",
        },
        {
          key: "model",
          label: "Model",
        },

        {
          key: "color",
          label: "Color",
        },
      ],
    };
  },
  methods: {
    onRowSelected(items) {
      this.$emit("vehicles-selected", items);
    },
  },
  mounted() {
    if (!this.isSelectMode) {
      this.fields.shift();
    }
  },
};
</script>
