<template>
  <div>
    <Nav @set-status="onChangeReportType" />
    <b-container fluid>
      <b-row>
        <template v-if="reportType === 1">
          <b-col sm="12" md="3" lg="3" xl="3" class="mt-3">
            <ReportSelect @onReportSelect="onReportSelect" />
          </b-col>
          <b-col sm="12" md="12" lg="12" xl="12" class="mt-3">
            <ReportTablePreview
              :fields="reportSelected ? reportSelected.columns : []"
              :report="reportSelected"
              @onChangeColumns="onChangeColumns"
            />
          </b-col>
        </template>
        <template v-if="reportType == 2">
          <b-col sm="12" md="12" lg="12" xl="12" class="mt-3">
            <ReportPoliceBook />
          </b-col>
        </template>
      </b-row>
      <ReportFormModal :refresh="getReports" />
    </b-container>
  </div>
</template>

<script>
import ReportFormModal from "./ReportFormModal.vue";
import ReportSelect from "./ReportSelect.vue";
import ReportTablePreview from "./ReportTablePreview.vue";
import Nav from "./Nav.vue";
import ReportPoliceBook from "./ReportPoliceBook.vue";
export default {
  name: "ReportsView",
  components: {
    ReportTablePreview,
    ReportSelect,
    ReportFormModal,
    Nav,
    ReportPoliceBook,
  },
  data() {
    return { reportSelected: null, reportType: 1 };
  },
  mounted() {
    this.$store.commit("setAddFunction", this.openReportFormModal);
  },
  methods: {
    onReportSelect(report) {
      this.reportSelected = { ...report };
    },
    onChangeColumns(columns) {
      this.reportSelected.columns = columns;
      this.getReports();
    },
    openReportFormModal() {
      this.$bvModal.show("report-form-modal");
    },
    getReports() {
      this.$store
        .dispatch("GET_CUSTOM_REPORTS")
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    },
    onChangeReportType(reportType) {
      console.log(reportType);
      this.reportType = reportType;
    },
  },
};
</script>

<style></style>
