export const PUT_ROTATE_IMAGE = "PUT_ROTATE_IMAGE";
export const PUT_ROTATE_IMAGE_SUCCESS = "PUT_ROTATE_IMAGE_SUCCESS";
export const PUT_ROTATE_IMAGE_ERROR = "PUT_ROTATE_IMAGE_ERROR";

export const PUT_REGISTER_DAMAGE = "PUT_REGISTER_DAMAGE";
export const PUT_REGISTER_DAMAGE_SUCCESS = "PUT_REGISTER_DAMAGE_SUCCESS";
export const PUT_REGISTER_DAMAGE_ERROR = "PUT_REGISTER_DAMAGE_ERROR";

export const GET_VI_CR_FILE = "GET_VI_CR_FILE";
export const GET_VI_CR_FILE_SUCCESS = "GET_VI_CR_FILE_SUCCESS";
export const GET_VI_CR_FILE_ERROR = "GET_VI_CR_FILE_ERROR";