export const GET_REIMPORT_DATA = "GET_REIMPORT_DATA";
export const GET_REIMPORT_DATA_SUCCESS = "GET_REIMPORT_DATA_SUCCESS";
export const GET_REIMPORT_DATA_ERROR = "GET_REIMPORT_DATA_ERROR";

export const POST_REIMPORT_DATA = "POST_REIMPORT_DATA";
export const POST_REIMPORT_DATA_SUCCESS = "POST_REIMPORT_DATA_SUCCESS";
export const POST_REIMPORT_DATA_ERROR = "POST_REIMPORT_DATA_ERROR";

export const GET_EXPORT_LOAD_LIST = "GET_EXPORT_LOAD_LIST";
export const GET_EXPORT_LOAD_LIST_SUCCESS = "GET_EXPORT_LOAD_LIST_SUCCESS";
export const GET_EXPORT_LOAD_LIST_ERROR = "GET_EXPORT_LOAD_LIST_ERROR";