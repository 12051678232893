<template>
  <div>
    <div class="d-flex justify-content-end">
      <b-button
        variant="outline-success"
        @click="downloadReport"
        class="mb-2"
        :disabled="reportExcelStatus == 'fetching'"
      >
        <!-- spinner -->
        <b-spinner
          v-if="reportExcelStatus == 'fetching'"
          class="align-middle"
          variant="success"
          small
        />

        <font-awesome-icon
          v-else
          class="cursor-pointer"
          :icon="['far', 'file-excel']"
        />
        {{ reportExcelStatus == "fetching" ? "Downloading..." : "Download" }}
      </b-button>
    </div>
    <b-table
      v-if="fields.length > 0"
      show-empty
      bordered
      small
      stacked="md"
      responsive
      head-variant="light"
      :items="policeBookReport.data || []"
      :fields="fields"
      :busy="isLoading"
      :empty-text="
        fields.length === 0 ? 'No fields selected' : 'No matching records'
      "
    >
      <template #head()="scope">
        <div class="table-head-menu">
          <span class=""> {{ scope.label }} </span>
          <br />
          <div class="text-center">
            <FilterColumn
              ref="filter-component"
              :type="scope.field.filterType"
              v-if="scope.field.hasFilter == 1"
              @applyFilters="applyFilters"
            />
            <div v-else>
              <br />
              <span class=""></span>
            </div>
          </div>
        </div>
      </template>
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle" variant="yellow" />
        </div>
      </template>

      <template v-slot:cell(stockNumber)="{ item }">
        {{ item.stockNumber }}
      </template>
      <template v-slot:cell(vinNumber)="{ item }">
        {{ item.vinNumber }}
      </template>
      <template v-slot:cell(stockDays)="{ item }">
        {{ item.stockDays }}
      </template>
      <template v-slot:cell(make)="{ item }">
        {{ item.make }}
      </template>
      <template v-slot:cell(model)="{ item }">
        {{ item.model }}
      </template>
      <template v-slot:cell(year)="{ item }">
        {{ item.year }}
      </template>
      <template v-slot:cell(miles)="{ item }">
        {{ item.miles | formatNumber }}
      </template>
      <template v-slot:cell(color)="{ item }">
        {{ item.color }}
      </template>
      <template v-slot:cell(buyingDate)="{ item }">
        {{ item.buyingDate | formatDate }}
      </template>
      <template v-slot:cell(buyFrom)="{ item }">
        {{ item.buyFrom }}
      </template>

      <template v-slot:cell(address)="{ item }">
        {{ item.clientStreet }} {{ item.clientCity }} {{ item.clientState }}
        {{ item.clientZip }}
      </template>
      <template v-slot:cell(soldToAddress)="{ item }">
        {{ item.street }} {{ item.city }} {{ item.state }} {{ item.zip }}
      </template>

      <template v-slot:cell(registerDate)="{ item }">
        {{ item.registerDate | formatDate }}
      </template>
    </b-table>

    <b-pagination
      v-if="!isLoading"
      :value="policeBookReport ? policeBookReport.currentPage : 1"
      :total-rows="policeBookReport ? policeBookReport.total : 0"
      :per-page="policeBookReport ? policeBookReport.perPage : 25"
      class="mb-2"
      align="end"
      @change="changePage($event)"
    />
  </div>
</template>
<script>
import { mapState } from "vuex";
import {
  GET_POLICE_BOOK_DATA_REPORT,
  GET_POLICE_BOOK_DATA_REPORT_EXCEL,
} from "./actions";
export default {
  name: "ReportPoliceBook",
  computed: {
    ...mapState({
      policeBookReport: (state) =>
        state.superAdminStore.customReports.policeBookData.data,
      isLoading: (state) =>
        state.superAdminStore.customReports.policeBookData.status == "fetching",
      reportExcelStatus: (state) =>
        state.superAdminStore.customReports.policeBookDataExcel.status,
    }),
  },
  data() {
    return {
      fields: [
        {
          key: "stockNumber",
          label: "Stock Number",
        },
        {
          key: "titleMsoOut",
          label: "Title Number",
        },
        {
          key: "vinNumber",
          label: "VIN Number",
        },
        {
          key: "year",
          label: "Year",
        },
        {
          key: "make",
          label: "Make",
        },
        {
          key: "model",
          label: "Model",
        },
        {
          key: "trim",
          label: "Trim",
        },
        {
          key: "color",
          label: "color",
        },
        {
          key: "buyingDate",
          label: "Buying Date",
        },
        {
          key: "clientName",
          label: "Bought From",
        },
        {
          key: "address",
          label: "Address",
        },
        {
          key: "companyName",
          label: "Sold To",
        },
        {
          key: "soldToAddress",
          label: "Sold To Address",
        },
        {
          key: "registerDate",
          label: "Date of Sale",
        },
      ],
      searchLocal: "",
    };
  },

  methods: {
    getData(search = null, page = 1) {
      this.searchLocal = search;
      const params = {
        page,
        search,
      };
      this.$store.dispatch(GET_POLICE_BOOK_DATA_REPORT, params).then(() => {});
    },
    changePage(page) {
      this.getData(null, page);
    },
    downloadReport() {
      const payload = {
        search: this.searchLocal,
      };
      this.$store
        .dispatch(GET_POLICE_BOOK_DATA_REPORT_EXCEL, payload)
        .then((response) => {
          const blob = new Blob([response], {
            type: "application/vnd.ms-excel",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = this.formatFileName("police-book") + ".xls";
          link.click();
        })
        .catch(() => {
          this.$root.$bvToast.toast("Could not get XLS", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    formatFileName(module) {
      let fileName = "";
      const today = new Date();
      fileName = `${module}-${today.getFullYear()}-${today.getMonth()}-${today.getDate()}-${today.getHours()}-${today.getMinutes()}-${today.getSeconds()}`;
      return fileName;
    },
  },
  mounted() {
    this.$store.commit("setSearchFunction", this.getData);

    this.getData();
  },
};
</script>
