import * as constants from "./actions";
import axios from "@/plugins/axios.js";


function getDefaultState() {
  return {
    reImportData: {
      status: null,
      data: [],
      error: null
    },
    exportLoadList: {
      status: null,
      data: [],
      error: null
    }
  }
}

const actions = {
  [constants.GET_REIMPORT_DATA]: async ({ commit }, payload) => {
    commit(constants.GET_REIMPORT_DATA);
    try {
      const response = await axios.get("exportLoad/getReImport", payload);
      commit(constants.GET_REIMPORT_DATA_SUCCESS, response.data);
    } catch (error) {
      commit(constants.GET_REIMPORT_DATA_ERROR, error);
    }
  },
  [constants.GET_EXPORT_LOAD_LIST]: async ({ commit }, payload) => {
    commit(constants.GET_EXPORT_LOAD_LIST);
    try {
      const response = await axios.get("exportLoad/getExportLoadList", payload);
      commit(constants.GET_EXPORT_LOAD_LIST_SUCCESS, response);
    } catch (error) {
      commit(constants.GET_EXPORT_LOAD_LIST_ERROR, error);
    }
  },
  [constants.POST_REIMPORT_DATA]: async ({ commit }, payload) => {
    commit(constants.POST_REIMPORT_DATA);
    try {
      const response = await axios.post("exportLoad/createReImport", payload);
      commit(constants.POST_REIMPORT_DATA_SUCCESS, response.data);
    } catch (error) {
      commit(constants.POST_REIMPORT_DATA_ERROR, error);
    }
  }
}

const mutations = {
  [constants.GET_REIMPORT_DATA]: (state) => {
    state.reImportData.status = "fetching ";
  },
  [constants.GET_REIMPORT_DATA_SUCCESS]: (state, data) => {
    state.reImportData.status = "success";
    state.reImportData.data = data;
  },
  [constants.GET_REIMPORT_DATA_ERROR]: (state, error) => {
    state.reImportData.status = "error";
    state.reImportData.error = error;
  },
  [constants.GET_EXPORT_LOAD_LIST]: (state) => {
    state.exportLoadList.status = "fetching ";
  },
  [constants.GET_EXPORT_LOAD_LIST_SUCCESS]: (state, data) => {
    state.exportLoadList.status = "success";
    console.log("data", data);
    state.exportLoadList.data = data;
  },
  [constants.GET_EXPORT_LOAD_LIST_ERROR]: (state, error) => {
    state.exportLoadList.status = "error";
    state.exportLoadList.error = error;
  },
  [constants.POST_REIMPORT_DATA]: (state) => {
    state.reImportData.status = "fetching ";
  },
  [constants.POST_REIMPORT_DATA_SUCCESS]: (state, data) => {
    state.reImportData.status = "success";
    state.reImportData.data = data;
  },
  [constants.POST_REIMPORT_DATA_ERROR]: (state, error) => {
    state.reImportData.status = "error";
    state.reImportData.error = error;
  }
}

export default {
  state: getDefaultState(),
  actions,
  mutations,
};