export const GET_CUSTOM_REPORTS = 'GET_CUSTOM_REPORTS';
export const GET_CUSTOM_REPORTS_SUCCESS = 'GET_CUSTOM_REPORTS_SUCCESS';
export const GET_CUSTOM_REPORTS_ERROR = 'GET_CUSTOM_REPORTS_ERROR';

export const POST_CUSTOM_REPORT = 'POST_CUSTOM_REPORT';
export const POST_CUSTOM_REPORT_SUCCESS = 'POST_CUSTOM_REPORT_SUCCESS';
export const POST_CUSTOM_REPORT_ERROR = 'POST_CUSTOM_REPORT_ERROR';

export const GET_COLUMNS_REPORTS = 'GET_COLUMNS_REPORTS';
export const GET_COLUMNS_REPORTS_SUCCESS = 'GET_COLUMNS_REPORTS_SUCCESS';
export const GET_COLUMNS_REPORTS_ERROR = 'GET_COLUMNS_REPORTS_ERROR';

export const GET_CUSTOM_REPO_INFO = 'GET_CUSTOM_REPO_INFO';
export const GET_CUSTOM_REPO_INFO_SUCCESS = 'GET_CUSTOM_REPO_INFO_SUCCESS';
export const GET_CUSTOM_REPO_INFO_ERROR = 'GET_CUSTOM_REPO_INFO_ERROR';


export const GET_CUSTOM_REPORT_EXCEL = "GET_CUSTOM_REPORT_EXCEL"
export const GET_CUSTOM_REPORT_EXCEL_SUCCESS = "GET_CUSTOM_REPORT_EXCEL_SUCCESS"
export const GET_CUSTOM_REPORT_EXCEL_ERROR = "GET_CUSTOM_REPORT_EXCEL_ERROR"

export const SET_REPORT_FILTERS = "SET_REPORT_FILTERS"

export const GET_REPORT_FILTERS_LIST = "GET_REPORT_FILTERS_LIST"
export const GET_REPORT_FILTERS_LIST_SUCCESS = "GET_REPORT_FILTERS_LIST_SUCCESS"
export const GET_REPORT_FILTERS_LIST_ERROR = "GET_REPORT_FILTERS_LIST_ERROR"

export const SET_COLUMNS_SELECTED = "SET_COLUMNS_SELECTED"

export const GET_POLICE_BOOK_DATA_REPORT = "GET_POLICE_BOOK_DATA_REPORT"
export const GET_POLICE_BOOK_DATA_REPORT_SUCCESS = "GET_POLICE_BOOK_DATA_REPORT_SUCCESS"
export const GET_POLICE_BOOK_DATA_REPORT_ERROR = "GET_POLICE_BOOK_DATA_REPORT_ERROR"

export const GET_POLICE_BOOK_DATA_REPORT_EXCEL = "GET_POLICE_BOOK_DATA_REPORT_EXCEL"
export const GET_POLICE_BOOK_DATA_REPORT_EXCEL_SUCCESS = "GET_POLICE_BOOK_DATA_REPORT_EXCEL_SUCCESS"
export const GET_POLICE_BOOK_DATA_REPORT_EXCEL_ERROR = "GET_POLICE_BOOK_DATA_REPORT_EXCEL_ERROR"