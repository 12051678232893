<template>
  <div class="">
    <Nav @set-status="setStatus" />

    <b-table
      show-empty
      bordered
      small
      stacked="md"
      head-variant="light"
      :items="reImport.data"
      :fields="fields"
      :busy="reImport.status === 'fetching'"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle" variant="yellow" />
        </div>
      </template>

      <template v-slot:cell(actions)="row">
        <div class="text-center">
          <b-icon
            v-if="row.field.details"
            v-b-tooltip.hover
            title="Details"
            icon="eye"
            class="h5 m-2 cursor-pointer"
            @click="row.toggleDetails"
          />

          <b-icon
            icon="box-arrow-right"
            v-if="row.item.vehicles.length > 0 && row.item.status == 1"
            v-b-tooltip.hover
            title="Mark as shipped"
            class="h5 m-2 cursor-pointer"
            @click="openDateModal(row.item)"
          ></b-icon>
          <b-icon
            icon="x-circle"
            v-if="row.item.status == 1"
            v-b-tooltip.hover
            title="Cancel"
            class="h5 m-2 cursor-pointer"
            @click="updateStatus(row.item.id, 0)"
          ></b-icon>
          <b-icon
            icon="check-square"
            v-if="row.item.status == 2"
            v-b-tooltip.hover
            title="Mark as closed"
            class="h5 m-2 cursor-pointer"
            @click="openDateModal(row.item)"
          ></b-icon>
        </div>
      </template>

      <template #row-details="{ item }">
        <b-card>
          <b-row>
            <b-col sm="12" md="12" lg="12">
              <VehiclesTable
                class="mt-2 px-3"
                :vehicles="item.vehicles"
                :isSelectMode="false"
              />
            </b-col>
            <!--               <b-col sm="12" md="6" lg="6">
                <AddonsBreakdown class="mt-2" :expensesInformation="item" />
              </b-col> -->
          </b-row>
        </b-card>
      </template>
    </b-table>
    <b-pagination
      v-if="reImport.status != 'fetching'"
      :value="reImport.data.currentPage"
      :total-rows="reImport.data.total"
      :per-page="reImport.data.perPage"
      class="mb-2"
      align="end"
      @change="changePage($event)"
    />

    <b-modal
      id="reImportDetailsModal"
      ref="reImportDetailsModal"
      size="lg"
      hide-footer
      body-class="pl-0 pr-0"
      ok-disabled
      scrollable
      @hide="hideModal"
    >
      <ReImportForm
        :reImportItem="reImportItem"
        :refreshData="getData"
        :hideModal="hideModal"
      />
    </b-modal>
  </div>
</template>
<script>
import { GET_REIMPORT_DATA } from "./actions";
import Nav from "./Nav.vue";
import { mapState } from "vuex";
import { generalMixin } from "@/modules/mixin.js";
import { superAdminMixin } from "../mixins";
import ReImportForm from "./ReImportForm.vue";
import VehiclesTable from "./VehiclesTable.vue";
export default {
  name: "ReImportTable",
  components: {
    Nav,
    ReImportForm,
    VehiclesTable,
  },
  mixins: [generalMixin, superAdminMixin],

  computed: {
    ...mapState({
      reImport: (state) => state.superAdminStore.reImport.reImportData,
    }),
  },
  data() {
    return {
      fields: [
        {
          key: "createdAt",
          label: "Created at",
          class: "text-center",
          formatter: (date) => this.$options.filters.formatDate(date),
        },
        {
          key: "description",
          label: "Description",
          class: "text-center",
        },
        {
          key: "finalDate",
          label: "Landed date",
          class: "text-center",
          formatter: (date) => this.$options.filters.formatDate(date),
        },
        {
          key: "actions",
          label: "Actions",
          note: false,
          details: true,
          validation: false,
          delete: true,
        },
      ],
      options: {
        assign: false,
        rolFilter: false,
        add: false,
        fun: this.getData,
      },
      status: 1,
      reImportItem: {},
    };
  },
  mounted() {
    this.$store.commit(
      "setAddFunction",
      this.userHasPermission("exportLoads", "write") ? this.showModal : null
    );
    this.getData();
  },
  methods: {
    getData(filter = "") {
      this.$store.dispatch(GET_REIMPORT_DATA, {
        status: this.status,
        search: filter,
      });
    },
    setStatus(status) {
      this.status = status;
      this.getData();
    },
    showModal() {
      this.reImportItem = {};

      this.$bvModal.show("reImportDetailsModal");
    },
    hideModal() {
      this.$bvModal.hide("reImportDetailsModal");
    },
  },
};
</script>
