<template>
  <div class="container">
    <b-form class="m-4" @submit.stop.prevent="form.id ? update() : save()">
      <b-row>
        <!-- Export location -->
        <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
          <b-form-group label-size="md">
            <template slot="label">
              <div>Export load</div>
            </template>
            <Multiselect
              v-model="form.exportLoad"
              :options="exportLoadList"
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="false"
              :preserve-search="false"
              placeholder="Pick some option"
              label="reference_number"
              track-by="id"
              :preselect-first="false"
              key="id"
            >
              <template #option="props">
                <div class="option__desc">
                  {{ props.option.reference_number }}
                  <span v-if="props.option.location">
                    -
                    {{ props.option.location.name }}
                  </span>
                </div>
              </template>
            </Multiselect>
          </b-form-group>
        </b-col>

        <b-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
          <label>Description</label>
          <b-form-textarea
            id="textarea"
            v-model="form.description"
            placeholder=""
            rows="3"
            max-rows="6"
            class="mb-2 text-dark"
            autofocus
          ></b-form-textarea>
        </b-col>
        <b-col
          v-if="form.exportLoad"
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          cols="12"
        >
          <VehiclesTable
            :vehicles="form.exportLoad.vehicles || []"
            @vehicles-selected="setVehiclesSelected"
          />
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col sm="12" md="6" lg="6" xl="6" />
        <b-col sm="12" md="3" lg="3" xl="3" />
        <b-col sm="12" md="3" lg="3" xl="3">
          <b-btn
            v-if="userHasPermission('exportLoads', 'write')"
            block
            size="lg"
            type="submit"
            class="btn btn-success"
            :disabled="$v.form.$invalid"
          >
            Submit
            <b-spinner
              v-if="reImport.status == 'fetching'"
              small
              variant="white"
            />
          </b-btn>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { POST_REIMPORT_DATA, GET_EXPORT_LOAD_LIST } from "./actions";
import { mapState } from "vuex";
import { generalMixin } from "@/modules/mixin.js";
import Multiselect from "vue-multiselect";
import { superAdminMixin } from "../mixins";
import VehiclesTable from "./VehiclesTable.vue";
export default {
  mixins: [generalMixin, superAdminMixin],
  props: ["reImportItem", "refreshData", "hideModal"],
  components: {
    Multiselect,
    VehiclesTable,
  },

  computed: {
    ...mapState({
      vehicles: (state) => state.superAdminStore.exportLoad.vehicles,
      exportLoadList: (state) =>
        state.superAdminStore.reImport.exportLoadList.data,
      reImport: (state) => state.superAdminStore.reImport.reImportData,
    }),
  },

  data() {
    return {
      form: {
        id: null,

        exportLoad: null,
        description: "",
        vehicles: [],
      },
      vehiclesSelected: [],
    };
  },
  validations: {
    form: {
      supplier: {},
      driver: {},
    },
  },
  created() {
    this.getExportLoads();
  },
  mounted() {
    if (this.reImportItem.id) {
      this.formatForm();
    } else {
      this.resetForm();
      this.form.referenceNumber = this.exportLocations.referenceNumber;
    }
  },
  watch: {
    exportLocation() {
      if (this.exportLocation) {
        this.form.exportLocation = this.exportLocation;

        this.getVehicles();
      }
    },
  },
  methods: {
    closeModal(callback) {
      callback();
    },
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    save() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_REIMPORT_DATA, payload)
        .then(() => {
          this.makeToast(
            "Success",
            "Re import created successfully",
            "success"
          );
          this.form = {};
          this.refreshData();
          this.getExportLoads();
        })
        .catch(() => {
          this.$root.$bvToast.toast("Internal server error, try again later", {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    update() {
      if (this.form.status == 2 || this.form.status == 3) {
        this.continueModal();
        return;
      }
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_REIMPORT_DATA, payload)
        .then(() => {
          this.makeToast(
            "Success",
            "Export load updated successfully",
            "success"
          );
          this.continueModal();
          this.refreshData();
          this.getExportLocations();
        })
        .catch(() => {
          this.$root.$bvToast.toast("Internal server error, try again later", {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    editItem(item) {
      this.reImportItem = item;
      this.$bvModal.show("detailsModal");
    },

    formatPayload() {
      const payload = {
        id: this.reImportItem.id ? this.reImportItem.id : null,
        description: this.form.description,
        exportLoadsId: this.form.exportLoad.id,
        vehicles: this.vehiclesSelected,
      };

      return payload;
    },

    formatForm() {
      if (this.reImportItem.id) {
        this.form.id = this.reImportItem.id;
        this.exportLocation = this.exportLocations.locations.find(
          (item) => item.id == this.reImportItem.locationsId
        );
        if (this.reImportItem.supplierCompanyId) {
          this.typeCarrier = "supplier";
          this.form.supplier = this.suppliers.find(
            (item) => item.id == this.reImportItem.supplierCompanyId
          );
        }
        this.form.status = this.reImportItem.status;
        this.form.referenceNumber = this.reImportItem.referenceNumber;
        this.form.desiredDate = this.reImportItem.desiredDate;
        this.form.referenceNumber = this.reImportItem.referenceNumber;
        this.form.userRi = this.riCompanies.find(
          (item) => item.id == this.reImportItem.userRi
        );
      }
    },

    setVehiclesSelected(vehicles) {
      this.vehiclesSelected = vehicles;
    },

    resetForm() {
      this.form = {
        id: null,
        list: [],
        exportLocation: null,
        supplier: null,
        driver: null,
        referenceNumber: null,
        desiredDate: null,
        userRi: null,
      };
      this.exportLocation = null;
    },

    getExportLoads() {
      this.$store.dispatch(GET_EXPORT_LOAD_LIST).then(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.append-input {
  max-width: 40%;
}
</style>
